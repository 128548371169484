<template>
  <component  :is="productCategoryData === undefined ? 'div' : 'div'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="productCategoryData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product categorydata
      </h4>
      <div class="alert-body">
        No product category found with this product category id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'app-category-list'}"
        >
          Organisation List
        </b-link>
        for other organisations.
      </div>
    </b-alert>
    <template #title>
      <feather-icon
        icon="UserIcon"
        size="16"
        class="mr-0 mr-sm-50"
      />
      <span class="d-none d-sm-inline">Informatie</span>
    </template>

    <template v-if="productCategoryData">
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <b-form
            class="mb-2"
            @submit.prevent="handleSubmit(updateOrganisation)"
        >
          <b-card>
            <div class="d-flex mb-1">
            <feather-icon
              icon="UserIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              Productcategorie informatie
            </h4>
          </div>
            <b-row>

              <!-- Field: ID -->
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="ID"
                  label-for="id"
                >
                  <b-form-input
                    id="id"
                    v-model="productCategoryData.id"
                    readonly="readonly"
                  />
                </b-form-group>
              </b-col>

              <!-- CreatedDate -->
              <b-col
                  cols="12"
                  md="2"
              >
                <b-form-group
                    label="Aangemaakt op"
                    label-for="createTimestamp"
                >
                  <b-form-input
                      id="createTimestamp"
                      v-model="productCategoryData.createTimestamp"
                      readonly="readonly"
                  />
                </b-form-group>
              </b-col>

              <!-- Field: Name -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                    #default="validationContext"
                    name="orderValidationTime"
                    rules="required"
                >
                  <b-form-group
                    label="Naam"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="productCategoryData.name"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: orderValidationTime -->
              <b-col
                  cols="12"
                  md="4"
              >
                <validation-provider
                    #default="validationContext"
                    name="orderValidationTime"
                    rules="required|integer"
                >
                  <b-form-group
                      label="Maximale doorlooptijd validatie (in dgn)"
                      label-for="orderValidationTime"
                  >
                    <b-form-input
                        id="orderValidationTime"
                        v-model="productCategoryData.orderValidationTime"
                        :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="10"
              >
                <b-form-group
                  label="Beschrijving"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="productCategoryData.description"
                    rows="3"
                    no-resize
                    maxlength="100"
                    trim
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                  cols="12"
              >
                <!-- Header: company Info -->
                <div class="d-flex mt-1 mb-1">
                  <feather-icon
                      icon="UserIcon"
                      size="19"
                  />
                  <h4 class="mb-0 ml-50">
                    Toegewezen medewerkers
                  </h4>
                </div>
                <b-form-group label="Gekoppelde managers">
                  <v-select
                      v-model="productCategoryData.managers"
                      dir="ltr"
                      multiple
                      label="fullname"
                      :reduce="val => val.id"
                      :options="usersData"
                  />
                </b-form-group>
                <b-form-group label="Gekoppelde backoffice medewerkers">
                  <v-select
                      v-model="productCategoryData.employees"
                      dir="ltr"
                      multiple
                      label="fullname"
                      :reduce="val => val.id"
                      :options="usersData"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
          <b-row>
            <b-col
                cols="12"
            >
              <b-card>
                <b-row>
                  <b-col
                      cols="6 text-left"
                  >
                    <b-button
                        variant="secondary"
                        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        type="button"
                        :to="{ name: 'app-category-list' }"
                    >
                      <span>Terug</span>
                    </b-button>
                  </b-col>
                  <b-col
                      cols="6 text-right"
                  >
                    <b-button
                        v-if="$can('update', 'category')"
                        variant="primary"
                        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                        type="submit"
                    >
                      <span>Opslaan</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </template>

  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BRow,
  BCol,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { required, integer } from '@validations'
import productOrganisationStoreModule from '@/views/pages/products/category/productOrganisationStoreModule'
import { avatarText } from '@core/utils/filter'
import { formatDateTime } from '@core/utils/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BRow,
    BCol,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      productCategoryData: {
        name: '',
        description: '',
        createTimestamp: '',
        managers: '',
        employees: '',
      },
      usersData: [],
      required,
      integer,
    }
  },
  beforeMount() {
    store.dispatch('app-product-category-edit/fetchUsers', { sort: 'name', role: '1,2,3', includingSelf: 1 })
      .then(response => {
        this.usersData = response.data.items
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de managers',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  mounted() {
    store.dispatch('app-product-category-edit/fetchProductCategory', { id: router.currentRoute.params.id })
      .then(response => {
        response.data.createTimestamp = formatDateTime(response.data.createTimestamp)
        this.productCategoryData = response.data
      })
      .catch(() => {
        this.productCategoryData = undefined
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error bij het ophalen van de categorieën',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    updateOrganisation() {
      store.dispatch('app-product-category-edit/updateProductCategory', this.productCategoryData)
        .then(() => {
          router.push({ name: 'app-category-list' })
        })
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-product-category-edit'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, productOrganisationStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      avatarText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
